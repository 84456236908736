import React from "react";
import { useSelector } from "../../../store";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";
import { Button, onError, InputWraper, useForm, CreateAlert } from "../../../modules";
import { InputNumber, InputText } from "../../../components";
import { store } from "../../../store";
import { translate } from "../../../languages";
import * as Yup from "yup";
export const WithdrawConfig = withConfigWraper(() => {
  const withdrawConfig = useSelector((state) => state.withdrawConfig);

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "userWithdrawMaxOnDay",
        defaultValue: Number(withdrawConfig.USER_MAXIMUM_IN_DAY),
        validate: Yup.number().required(translate("must be provided")).min(1, translate("not filled in zero")),
      },
      {
        name: "systemWithdrawMaxOnDay",
        defaultValue: Number(withdrawConfig.SYSTEM_MAXIMUM_IN_DAY),
        validate: Yup.number().required(translate("must be provided")).min(1, translate("not filled in zero")),
      },
      {
        name: "BEP20_FEE",
        defaultValue: Number(withdrawConfig.BEP20_FEE),
        validate: Yup.number().required(translate("must be provided")).min(1, translate("not filled in zero")),
      },
      {
        name: "TRC20_FEE",
        defaultValue: Number(withdrawConfig.TRC20_FEE),
        validate: Yup.number().required(translate("must be provided")).min(1, translate("not filled in zero")),
      },
      {
        name: "WITHDRAW_PROCESSING_TIME",
        defaultValue: withdrawConfig.WITHDRAW_PROCESSING_TIME,
        validate: Yup.string().required(translate("must be provided")),
      },
    ],
    onSubmit: async (values) => {
      const valueConfig = {
        USER_MAXIMUM_IN_DAY: values.userWithdrawMaxOnDay,
        SYSTEM_MAXIMUM_IN_DAY: values.systemWithdrawMaxOnDay,
        BEP20_FEE: values.BEP20_FEE,
        TRC20_FEE: values.TRC20_FEE,
        WITHDRAW_PROCESSING_TIME: values.WITHDRAW_PROCESSING_TIME,
      };
      const payload = {
        isActiveScan: true,
        shouldProcessWithdraw: false,
        reservedEth: 0,
        withdrawRequestExpiration: 0,
        value: JSON.stringify(valueConfig),
        serverConfigName: "WITHDRAW_LIMIT_CONFIG",
      };
      return ConfigService.setConfig(payload)
        .then(async () => {
          CreateAlert({ type: "success", message: "Set config withdraw successful." });
        })
        .catch(onError);
    },
  });

  return (
    <div className="row mb20">
      <div className="col-sm-5">
        <form className="blockLists" onSubmit={handleSubmit}>
          <InputWraper label={"User Withdraw Max On Day"} inputProps={getInputProps("userWithdrawMaxOnDay")} component={InputNumber} />
          <InputWraper label={"System Withdraw Max On Day"} inputProps={getInputProps("systemWithdrawMaxOnDay")} component={InputNumber} />
          <InputWraper label={"BEP20 Withdraw Fee"} inputProps={getInputProps("BEP20_FEE")} component={InputNumber} />
          <InputWraper label={"TRC20 Withdraw Fee"} inputProps={getInputProps("TRC20_FEE")} component={InputNumber} />
          <InputWraper label={"Withdraw Processing Time"} inputProps={getInputProps("WITHDRAW_PROCESSING_TIME")} component={InputText} />
          <div>
            <Button
              // className="mb20"
              label="Submit"
              // onClick={props.onClick}
              isMiddle
              buttonType="success"
              className="btnBan"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
});
