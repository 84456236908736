import React, { FC, useState } from "react";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { getEnv } from "../../../configs";
import { Button, CreateAlert, InputWraper, NumberUtils, Table, onError, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withFranchiseWraper } from "../wraper";
import * as Yup from "yup";
import { PopupWraper } from "../../../components/popup";
import { Icon, InputNumber, InputSelect } from "../../../components";
import { NavLink } from "react-router-dom";
import { translate } from "../../../languages";
import ProgressBar from "@ramonak/react-progress-bar";
import { TransferMetWallet } from "./components";

export const selectRank = (rank: any) => {
  switch (rank) {
    case 0:
      return "Member";
    case 1:
      return "Trader 1";
    case 2:
      return "Trader 2";
    case 3:
      return "Trader 3";
    case 4:
      return "Manager";
    case 5:
      return "Director";
    case 6:
      return "Managing Director";
    case 7:
      return "Partner";
    case 8:
      return "Global Partner";
    default:
      return "Member";
  }
};

export const PageFranchiseTable = withFranchiseWraper(() => {
  // const user = useSelector((state) => state.user);
  const [currentData, setCurrentData] = useState(null as any);
  const [currentUserSetLevel, setCurrentUserSetLevel] = useState<any>();
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowTable, setIsShowTable] = useState<boolean>(true);
  const [params, setParams] = useState<any>();
  const [openMetTransfer, setOpenMetTransfer] = useState<any>(null);

  // const startOfMonth = moment().subtract(1, "months").startOf("month");
  // const endOfDay = moment().endOf("day");

  return (
    <div className="PageFranchiseTable">
      <Button
        disabled={!currentData}
        label="Export to Excel"
        buttonType="success"
        className="mb15"
        onClick={async () => {
          const response = await AdminService.getListAffilateTable({
            ...params,
            pageSize: 10000,
            page: 1,
          });
          const data = [
            [
              "User",
              "Total Volume",
              "Profit",
              // "Withdraw",
              // "MTG Balance",
              "USDT Balance",
              "BONUS Balance",
              "TICKET Balance",
              "MET Balance",
              "MEC Balance",
              "Daily Commission",
              "Order Fee",
              // "Direct Commission",
              // "Cashback",
              // "Exchange In",
              // "Exchange Out",
              "Direct Sale",
              "Group Sale",
              "Rank",
              // "Agency Count",
            ],
            ...response.data.map((item: any) => {
              let ParseIP = item?.ip ? `IP: ${item?.ip}` : `IP: ?`;
              let row: any[] = [];
              row.push(item?.userName + "\n" + item?.firstName + "\n" + item?.email + "\n" + ParseIP);
              row.push(item?.volume ? NumberUtils.toFormatNumber(+item?.volume, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              row.push(item?.profitVolume ? NumberUtils.toFormatNumber(+item?.profitVolume, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              // row.push(`USDT: ${item?.withdrawUsdt ? NumberUtils.toFormatNumber(+item?.withdrawUsdt, 4) : `0.00000000`}`);
              // row.push(
              //   item?.balanceGp2
              //     ? NumberUtils.toFormatNumber(+item?.balanceGp2, +getEnv("NUMBER_DECIMAL_DISPLAY"))
              //     : `0.00000000`
              // );
              row.push(item?.balanceUsdt ? NumberUtils.toFormatNumber(+item?.balanceUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              row.push(
                item?.packageTotalVolumeF1 ? NumberUtils.toFormatNumber(+item?.packageTotalVolumeF1, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`
              );
              row.push(
                item?.packageTotalVolumeDownline
                  ? NumberUtils.toFormatNumber(+item?.packageTotalVolumeDownline, +getEnv("NUMBER_DECIMAL_DISPLAY"))
                  : `0.00000000`
              );
              row.push(item?.balanceBonus ? NumberUtils.toFormatNumber(+item?.balanceBonus, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              row.push(item?.balanceTicket ? NumberUtils.toFormatNumber(+item?.balanceTicket, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              row.push(item?.balanceMet ? NumberUtils.toFormatNumber(+item?.balanceMet, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              row.push(item?.balanceMec ? NumberUtils.toFormatNumber(+item?.balanceMec, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              row.push(item?.dailyCommission ? NumberUtils.toFormatNumber(+item?.dailyCommission, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              row.push(item?.orderFee ? NumberUtils.toFormatNumber(+item?.orderFee, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              // row.push(
              //   item?.cashBack
              //     ? NumberUtils.toFormatNumber(+item?.cashBack, +getEnv("NUMBER_DECIMAL_DISPLAY"))
              //     : `0.00000000`
              // );
              // row.push(
              //   item?.exchangeIn
              //     ? NumberUtils.toFormatNumber(+item?.exchangeIn, +getEnv("NUMBER_DECIMAL_DISPLAY"))
              //     : `0.00000000`
              // );
              // row.push(
              //   item?.exchangeOut
              //     ? NumberUtils.toFormatNumber(
              //         +item?.exchangeOut,
              //         +getEnv("NUMBER_DECIMAL_DISPLAY")
              //       )
              //     : `0.00000000`
              // );
              row.push(selectRank(item?.rank));

              return row;
            }),
          ];

          const ws = XLSX.utils.aoa_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(wb, `Franchise ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
            type: "binary",
          });
        }}
      />

      {isShowTable && (
        <Table
          hasOrderColumn
          className="overload"
          filters={[
            {
              name: "Username",
              key: "searchString",
              input: TableFilterInputText,
            },
            {
              name: "Date",
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" format={"MM/dd/y"} />,
              // defaultValue: {
              //     //fromDate: moment().subtract(1,"months"),
              //     //toDate: moment(),
              //     fromDate: startOfMonth,
              //     toDate: endOfDay,
              // },
            },
          ]}
          structure={[
            {
              name: "User",
              key: "q",
              render: (item) => {
                return (
                  <div className="user">
                    Username:{" "}
                    <span className="user-clickable">
                      {item.userName} <br />
                    </span>
                    Display name:{" "}
                    <span className="user-clickable">
                      {item.firstName} <br />
                    </span>
                    Email:{" "}
                    <span className="user-clickable">
                      {item.email} <br />
                    </span>
                    <span className="ip">IP: {item?.ip ?? "?"}</span>
                  </div>
                );
              },
            },
            {
              name: "Total Volume",
              key: "volume",
              render: (item) => {
                const value = +item.volume;
                return value ? NumberUtils.toFormatNumber(+item.volume, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
              },
              sort: {},
            },
            {
              name: "Profit",
              key: "profitVolume",
              render: (item) => {
                const value = +item.profitVolume;
                return value ? (
                  <span className={item?.profitVolume === 0 ? "" : item?.profitVolume > 0 ? "profit-volume-green" : "profit-volume-red"}>
                    {NumberUtils.toFormatNumber(+item?.profitVolume, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                  </span>
                ) : (
                  <span className="profit-volume-green">0.00000000</span>
                );
              },
              sort: {},
            },
            // {
            //     name: "Won volume",
            //     key: "wonVolume",
            //     className: "textSuccess",
            //     render: (item) => {
            //         return `$${(+item.wonVolume).toLocaleString(
            //             getLocaleKey(),
            //             {
            //                 maximumFractionDigits: 2,
            //                 minimumFractionDigits: 2,
            //             }
            //         )}`;
            //     },
            //     sort: {},
            // },
            // {
            //     name: "Lose volume",
            //     key: "lostVolume",
            //     className: "textDanger",
            //     render: (item) => {
            //         const value = +item.lostVolume;
            //         return value
            //             ? `$${(+item.lostVolume).toLocaleString(
            //                   getLocaleKey(),
            //                   {
            //                       maximumFractionDigits: 2,
            //                       minimumFractionDigits: 2,
            //                   }
            //               )}`
            //             : "$0.00";
            //     },
            //     sort: {},
            // },
            // {
            //     name: "Draw volume",
            //     key: "drawVolume",
            //     className: "textWarning",
            //     render: (item) => {
            //         const value = +item.drawVolume;
            //         return value
            //             ? `$${(+item.drawVolume).toLocaleString(
            //                   getLocaleKey(),
            //                   {
            //                       maximumFractionDigits: 2,
            //                       minimumFractionDigits: 2,
            //                   }
            //               )}`
            //             : "$0.00";
            //     },
            //     sort: {},
            // },
            // {
            //   name: "Deposit",
            //   render: (item) => {
            //     return (
            //       <span>
            //         ETH:{" "}
            //         {(+item.depositEth).toLocaleString(getLocaleKey(), {
            //           maximumFractionDigits: 8,
            //           minimumFractionDigits: 8
            //         })}{" "}
            //         <br />
            //         USDT:{" "}
            //         {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
            //           maximumFractionDigits: 8,
            //           minimumFractionDigits: 8
            //         })}
            //       </span>
            //     );
            //   },
            // },
            // {
            //     name: "Withdraw",
            //     render: (item) => {
            //         return (
            //             <span>
            //                 USDT:{" "}
            //                 {NumberUtils.toFormatNumber(+item?.withdrawUsdt, 4)}
            //             </span>
            //         );
            //     },
            // },
            {
              name: "USDT Balance",
              key: "balanceUsdt",
              render: (item) => {
                return NumberUtils.toFormatNumber(+item?.balanceUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
              },
              sort: {},
            },
            {
              name: "BONUS Balance",
              key: "balanceBonus",
              render: (item) => {
                return NumberUtils.toFormatNumber(+item?.balanceBonus, +getEnv("NUMBER_DECIMAL_DISPLAY"));
              },
              sort: {},
            },
            {
              name: "TICKET Balance",
              key: "balanceTicket",
              render: (item) => {
                return NumberUtils.toFormatNumber(+item?.balanceTicket, +getEnv("NUMBER_DECIMAL_DISPLAY"));
              },
              sort: {},
            },
            {
              name: "MET Balance",
              key: "balanceMet",
              render: (item, fetchData) => {
                return (
                  <span
                    className="met-clickable"
                    onClick={() => setOpenMetTransfer({ userName: item?.userName, type: "MET_TRANSFER", fetchData: fetchData })}
                  >
                    {NumberUtils.toFormatNumber(+item?.balanceMet, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                  </span>
                );
              },
              sort: {},
            },
            {
              name: "MEC Balance",
              key: "balanceMec",
              render: (item) => {
                return NumberUtils.toFormatNumber(+item?.balanceMec, +getEnv("NUMBER_DECIMAL_DISPLAY"));
              },
              sort: {},
            },
            {
              name: "Daily Commission",
              key: "dailyCommission",
              render: (item) => {
                return NumberUtils.toFormatNumber(+item?.dailyCommission, +getEnv("NUMBER_DECIMAL_DISPLAY"));
              },
              sort: {},
            },
            {
              name: "Order Fee",
              key: "orderFee",
              render: (item) => {
                return NumberUtils.toFormatNumber(+item?.orderFee, +getEnv("NUMBER_DECIMAL_DISPLAY"));
              },
              sort: {},
            },
            // {
            //   name: "Direct Commission",
            //   key: "directCommission",
            //   render: (item) => {
            //     return `${(+item.directCommission).toLocaleString(
            //       getLocaleKey(),
            //       { maximumFractionDigits: 2, minimumFractionDigits: 2 }
            //     )}`;
            //   },
            //   sort: {},
            // },
            // {
            //   name: "Cashback 0.2%",
            //   key: "cashBack",
            //   render: (item) => {
            //     return NumberUtils.toFormatNumber(
            //       +item?.cashBack,
            //       +getEnv("NUMBER_DECIMAL_DISPLAY")
            //     );
            //   },
            //   sort: {},
            // },
            // {
            //   name: "Exchange In",
            //   key: "exchangeIn",
            //   render: (item) => {
            //     const value = +item.exchangeIn;
            //     return value
            //       ? NumberUtils.toFormatNumber(+item?.exchangeIn, +getEnv("NUMBER_DECIMAL_DISPLAY"))
            //       : "0.00000000";
            //   },
            //   sort: {},
            // },
            // {
            //   name: "Exchange Out",
            //   key: "exchangeOut",
            //   render: (item) => {
            //     const value = +item.exchangeOut;
            //     return value
            //       ? NumberUtils.toFormatNumber(
            //           +item?.exchangeOut,
            //           +getEnv("NUMBER_DECIMAL_DISPLAY")
            //         )
            //       : "0.00000000";
            //   },
            //   sort: {},
            // },
            {
              name: "Rank",
              key: "rank",
              render: (item) => {
                return (
                  <div
                    className="user"
                    onClick={() => {
                      setCurrentUserSetLevel(item);
                      setIsShowPopup(true);
                    }}
                  >
                    {selectRank(item?.rank)}
                  </div>
                );
              },
              // <img className="level" src={`/assets/images/rank/rank_${item?.rank}.png`} alt="" />
              // selectRank(item?.rank),
              sort: {},
            },
            {
              name: "Direct Sale",
              key: "packageTotalVolumeF1",
              render: (item) => {
                return (
                  <div className="direct-sales">
                    <div className="direct-sales__label">
                      <span style={{ color: "#5470C6" }}>
                        {item?.challengeType === "P"
                          ? translate("none-md-zone")
                          : item?.challengeType === "GP"
                          ? translate("small-group")
                          : translate("Direct Sales")}
                        :
                      </span>{" "}
                      $
                      {(item?.challengeType === "P" || item?.challengeType === "GP"
                        ? item?.nextLevelRequirements?.targetSmallGroupSale
                        : item?.nextLevelRequirements?.packageTargetVolumeF1) -
                        (item?.challengeType === "P" || item?.challengeType === "GP"
                          ? item?.nextLevelRequirements?.smallGroupSale
                          : item?.nextLevelRequirements?.packageTotalVolumeF1) <
                      0
                        ? 0
                        : (item?.challengeType === "P" || item?.challengeType === "GP"
                            ? item?.nextLevelRequirements?.targetSmallGroupSale
                            : item?.nextLevelRequirements?.packageTargetVolumeF1) -
                          (item?.challengeType === "P" || item?.challengeType === "GP"
                            ? item?.nextLevelRequirements?.smallGroupSale
                            : item?.nextLevelRequirements?.packageTotalVolumeF1)}{" "}
                      {translate("remaining")}
                    </div>
                    <div className="direct-sales__chart">
                      <ProgressBar
                        height={"24px"}
                        width={"200px"}
                        animateOnRender={true}
                        bgColor={"#5470C6"}
                        baseBgColor={"rgba(84, 112, 198, 0.4)"}
                        labelColor={"#000"}
                        // isLabelVisible={false}
                        isLabelVisible={true}
                        labelAlignment={"left"}
                        maxCompleted={100}
                        completed={
                          (
                            item?.challengeType === "P" || item?.challengeType === "GP"
                              ? item?.nextLevelRequirements?.targetSmallGroupSale === 0
                              : item?.nextLevelRequirements?.packageTargetVolumeF1 === 0
                          )
                            ? 100
                            : Number(
                                (
                                  ((item?.challengeType === "P" || item?.challengeType === "GP"
                                    ? item?.nextLevelRequirements?.smallGroupSale
                                    : item?.nextLevelRequirements?.packageTotalVolumeF1) *
                                    100) /
                                  (item?.challengeType === "P" || item?.challengeType === "GP"
                                    ? item?.nextLevelRequirements?.targetSmallGroupSale
                                    : item?.nextLevelRequirements?.packageTargetVolumeF1)
                                )
                                  .toString()
                                  .match(new RegExp("^-?\\d+(?:.\\d{0,2})?"))?.[0]
                              )
                        }
                      />
                    </div>
                  </div>
                );
              },
              sort: {},
            },
            {
              name: "Group Sale",
              key: "packageTotalVolumeDownline",
              render: (item) => {
                return (
                  <div className="group-sales">
                    <div className="group-sales__label">
                      <span style={{ color: "#91CC75" }}>
                        {item?.challengeType === "P"
                          ? translate("md-zone")
                          : item?.challengeType === "GP"
                          ? translate("big-group")
                          : translate("Group Sales")}
                        :
                      </span>{" "}
                      $
                      {(item?.challengeType === "P" || item?.challengeType === "GP"
                        ? item?.nextLevelRequirements?.targetBigGroupSale
                        : item?.challengeType === "MD"
                        ? 100000
                        : item?.nextLevelRequirements?.packageTargetVolumeDownline) -
                        (item?.challengeType === "P" || item?.challengeType === "GP"
                          ? item?.nextLevelRequirements?.bigGroupSale
                          : item?.challengeType === "MD"
                          ? item?.challengeGroupSale
                          : item?.nextLevelRequirements?.packageTotalVolumeDownline) <
                      0
                        ? 0
                        : (item?.challengeType === "P" || item?.challengeType === "GP"
                            ? item?.nextLevelRequirements?.targetBigGroupSale
                            : item?.challengeType === "MD"
                            ? 100000
                            : item?.nextLevelRequirements?.packageTargetVolumeDownline) -
                          (item?.challengeType === "P" || item?.challengeType === "GP"
                            ? item?.nextLevelRequirements?.bigGroupSale
                            : item?.challengeType === "MD"
                            ? item?.challengeGroupSale
                            : item?.nextLevelRequirements?.packageTotalVolumeDownline)}{" "}
                      {translate("remaining")}
                    </div>
                    <div className="group-sales__chart">
                      <ProgressBar
                        height={"24px"}
                        width={"200px"}
                        animateOnRender={true}
                        bgColor={"#91CC75"}
                        baseBgColor={"rgba(145, 204, 117, 0.4)"}
                        labelColor={"#000"}
                        // isLabelVisible={false}
                        isLabelVisible={true}
                        labelAlignment={"left"}
                        maxCompleted={100}
                        completed={
                          (
                            item?.challengeType === "P" || item?.challengeType === "GP"
                              ? item?.nextLevelRequirements?.targetBigGroupSale === 0
                              : item?.nextLevelRequirements?.packageTargetVolumeDownline === 0
                          )
                            ? 100
                            : Number(
                                (
                                  ((item?.challengeType === "P" || item?.challengeType === "GP"
                                    ? item?.nextLevelRequirements?.bigGroupSale
                                    : item?.challengeType === "MD"
                                    ? item?.challengeGroupSale
                                    : item?.nextLevelRequirements?.packageTotalVolumeDownline) *
                                    100) /
                                  (item?.challengeType === "P" || item?.challengeType === "GP"
                                    ? item?.nextLevelRequirements?.targetBigGroupSale
                                    : item?.challengeType === "MD"
                                    ? 100000
                                    : item?.nextLevelRequirements?.packageTargetVolumeDownline)
                                )
                                  .toString()
                                  .match(new RegExp("^-?\\d+(?:.\\d{0,2})?"))?.[0]
                              )
                        }
                      />
                    </div>
                  </div>
                );
              },
              sort: {},
            },

            // {
            //   name: "Agency Count",
            //   key: "agencyCount",
            //   render: (item) => {
            //     return `${(+item.agencyCount).toLocaleString(getLocaleKey(), {
            //       maximumFractionDigits: 2,
            //       minimumFractionDigits: 2
            //     })}`;
            //   },
            //   sort: {},
            // },
          ]}
          fetchData={async (state) => {
            // AdminService.getFranchiseUserReport(100, user.userId, params).then(
            //   (res) => {
            //     setCurrentData(res);
            //     return res;
            //   }
            // )
            let params = { ...state };
            setParams(params);
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
            return AdminService.getListAffilateTable({
              ...params,
              pageSize: params.limit,
              page: params.pageNumber,
            }).then((res) => {
              setCurrentData(res);
              return res;
            });
          }}
        />
      )}
      {isShowPopup && (
        <SetLevelPopup
          dataUser={currentUserSetLevel}
          onClose={(value: any) => setIsShowPopup(value)}
          onIsShowTable={(value: any) => setIsShowTable(value)}
        />
      )}
      {openMetTransfer && (
        <PopupWraper center title={"Transfer MET"} onClose={() => setOpenMetTransfer(null)}>
          <TransferMetWallet
            data={openMetTransfer}
            onFinish={() => {
              openMetTransfer?.fetchData();
              setOpenMetTransfer(null);
            }}
          />
        </PopupWraper>
      )}
    </div>
  );
});

const SetLevelPopup: FC<any> = (props) => {
  const enum EScreenIdx {
    SET_RANK = "Set Rank",
    BUNDLE = "Bundle",
  }
  const [screenIdx, setScreenIdx] = useState<any>(EScreenIdx.SET_RANK);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "rank",
        validate: Yup.number().required("Must be provided").integer("Number must be integer").max(8, "Maximum is 8").min(1, "Minimum is 1"),
      },
    ],
    onSubmit: async (values) => {
      props?.onIsShowTable(false);
      return AdminService.setLevel(props?.dataUser?.userId, values.rank)
        .then(() => {
          return CreateAlert({
            type: "success",
            message: "Set rank successfully.",
          });
        })
        .catch(onError)
        .finally(() => {
          props?.onIsShowTable(true);
          props?.onClose(false);
        });
    },
  });

  const OptionsRank = [
    { label: "Rank 3 - Trader", value: 3 },
    { label: "Rank 4 - Manager", value: 4 },
    { label: "Rank 5 - Director", value: 5 },
    { label: "Rank 6 - Managing Director", value: 6 },
    { label: "Rank 7 - Partner", value: 7 },
    { label: "Rank 8 - Global Partner", value: 8 },
  ];

  const handleConfirmCloseBundle = async (userId: any) => {
    if (isRequesting === true) return;
    setIsRequesting(true);
    await AdminService.closeBundle(userId)
      .then(() => {
        CreateAlert({
          type: "success",
          message: "close bundle successfully.",
        });
      })
      .catch(onError)
      .finally(() => {
        setIsRequesting(false);
        props?.onClose(false);
      });
  };

  return (
    <PopupWraper title={screenIdx === EScreenIdx.SET_RANK ? "Set Rank" : "Close Bundle"} onClose={() => props?.onClose(false)}>
      <div className="TabLinks">
        <div className={`nav-item ${screenIdx === EScreenIdx.SET_RANK ? "active" : ""}`} onClick={() => setScreenIdx(EScreenIdx.SET_RANK)}>
          <Icon.Setting />
          Rank
        </div>
        <div className={`nav-item ${screenIdx === EScreenIdx.BUNDLE ? "active" : ""}`} onClick={() => setScreenIdx(EScreenIdx.BUNDLE)}>
          <Icon.Setting />
          Bundle
        </div>
      </div>
      {screenIdx === EScreenIdx.SET_RANK && (
        <div style={{ fontSize: "16px" }} className="container">
          <fieldset style={{ marginBottom: "32px" }}>
            <legend>Information:</legend>
            <div className="display-name">
              Display name: <span style={{ color: "#fff" }}>{props?.dataUser?.firstName}</span>
            </div>
            <div className="email">
              Email: <span style={{ color: "#fff" }}>{props?.dataUser?.email}</span>
            </div>
            <div className="level">
              Current rank: <span style={{ color: "#fff" }}>{props?.dataUser?.rank}</span>
            </div>
          </fieldset>
          <InputWraper
            inputProps={getInputProps("rank")}
            renderInput={(props) => (
              <InputSelect
                {...props}
                options={OptionsRank}
                onChange={(e) => getInputProps("rank").onChange(e)}
                onTouched={() => false}
                value={getInputProps("rank").value}
                name=""
              />
            )}
          />
          <Button onClick={handleSubmit} isLoading={isSubmitting} label="Set rank" buttonType="success" type="submit" />
        </div>
      )}

      {screenIdx === EScreenIdx.BUNDLE && (
        <div style={{ fontSize: "16px" }} className="container">
          <div style={{ padding: "20px 0" }} className="title-bundle">
            Are you sure you want to close the bundle?
          </div>
          <div className="button-container">
            <Button onClick={() => handleConfirmCloseBundle(props?.dataUser?.userId)} isLoading={isRequesting} label="Confirm" buttonType="success" />
            <Button onClick={() => props?.onClose(false)} label="Cancel" buttonType="grey" />
          </div>
        </div>
      )}
    </PopupWraper>
  );
};

// export const SystemReportDetailPopup: FC<{
//     detail: any;
//     onClose: () => void;
// }> = (props) => {
//     if (!props.detail) return null;

//     const item = props.detail;

//     const data = [
//         {
//             label: "Start",
//             value: `${new Date(item.start).toLocaleDateString(getLocaleKey(), {
//                 hour12: false,
//             })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
//                 hour12: false,
//             })}`,
//         },
//         {
//             label: "End",
//             value: `${new Date(item.end).toLocaleDateString(getLocaleKey(), {
//                 hour12: false,
//             })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
//                 hour12: false,
//             })}`,
//         },
//         {
//             label: "Modified",
//             value: `${new Date(item.modified).toLocaleDateString(
//                 getLocaleKey(),
//                 {
//                     hour12: false,
//                 }
//             )} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
//                 hour12: false,
//             })}`,
//         },
//         {
//             label: "Total won/lose volume",
//             value: `${(+item.wonVolume).toLocaleString(getLocaleKey(), {
//                 maximumFractionDigits: 2,
//                 minimumFractionDigits: 2,
//             })}/${(+item.lostVolume).toLocaleString(getLocaleKey(), {
//                 maximumFractionDigits: 2,
//                 minimumFractionDigits: 2,
//             })}`,
//         },
//         {
//             label: "Hot Wallet",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.hotWalletUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Deposit",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.depositUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Withdraw",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.withdrawUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "User 's Balance",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.userBalanceUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Safe",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.safeUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.safeUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Login Count",
//             value: item.loginCount,
//         },
//         {
//             label: "Direct Commission",
//             value: item.directCommission,
//         },
//         {
//             label: "CashBack",
//             value: (+item.cashBack).toLocaleString(getLocaleKey(), {
//                 maximumFractionDigits: 2,
//                 minimumFractionDigits: 2,
//             }),
//         },
//         {
//             label: "New Package",
//             value: item.newPackage,
//         },
//         {
//             label: "Trading Commission",
//             value: item.rankCommission,
//         },
//     ];

//     return (
//         <div
//             className="SystemReport__DetailPopup"
//             id="SystemReport__DetailPopup"
//             onClick={(e: any) =>
//                 e.target.id === "SystemReport__DetailPopup"
//                     ? props.onClose()
//                     : null
//             }
//         >
//             <div className="box">
//                 <div className="boxTitle">
//                     <span>Detail Report</span>
//                     <div className="btnClose" onClick={() => props.onClose()}>
//                         <Icon.Close />
//                     </div>
//                 </div>
//                 <div className="content overload">
//                     <table>
//                         <tbody>
//                             {data.map((row, key) => (
//                                 <tr key={key}>
//                                     <td>{row.label}</td>
//                                     <td>{row.value}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };
