import React, { useState } from "react";
import { NumberUtils, Table } from "../../../modules";
import { withChallengeWraper } from "../wraper";
import { ChallengeService } from "../../../services/challenge";
import moment from "moment";

export const GlobalPartner = withChallengeWraper(() => {
  const [time, setTime] = useState([1, 2, 3, 9999]);

  return (
    <div className="table-challenge-root">
      <div className="title-merge-table">UserName</div>
      <Table
        className="table-challenge"
        notShowPagination
        structure={[
          {
            name: "Username",
            key: "userName",
            render: (item) =>
              Object.keys(item).length > 0 ? (
                <div>
                  <p>{item?.userName}</p>
                  <p
                    style={{
                      visibility: "hidden",
                    }}
                  >
                    a
                  </p>
                </div>
              ) : (
                <></>
              ),
          },
          ...time?.map((el) => ({
            name: `${el === 9999 ? "Total" : `Month ${el}`}`,
            key: `month${el}`,
            render: (item: any) => {
              const month = item?.months?.find((month: any) => month?.month === el);

              return month ? (
                <div className="row">
                  <div className="col-6 d-flex align-items-center justify-content-center">
                    <div>
                      <p className="mb4">{`${moment(month?.fromDate).local().format("DD/MM/YY")} - ${moment(month?.toDate)
                        .local()
                        .format("DD/MM/YY")}`}</p>
                      <p>{NumberUtils.toFormatNumber(+month?.zoneVolume)}</p>
                    </div>
                  </div>
                  <div className="col-6 d-flex align-items-center justify-content-center">
                    <div>
                      <p className="mb4">{`${moment(month?.fromDate).local().format("DD/MM/YY")} - ${moment(month?.toDate)
                        .local()
                        .format("DD/MM/YY")}`}</p>
                      <p>{NumberUtils.toFormatNumber(+month?.noneZoneVolume)}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-6 d-flex align-items-center justify-content-center">
                    <p>Partner Zone</p>
                  </div>
                  <div className="col-6 d-flex align-items-center justify-content-center">
                    <p>None Partner Zone</p>
                  </div>
                </div>
              );
            },
          })),
        ]}
        fetchData={async (state) => {
          return ChallengeService.challengeDashboard("GP").then((res) => {
            res?.data?.[0]?.months && setTime(res?.data?.[0]?.months?.map((item: any) => item?.month));
            return res?.count > 0
              ? {
                  count: res?.count + 1,
                  data: [{}, ...res.data],
                }
              : {
                  count: 0,
                  data: [{}],
                };
          });
        }}
      />
    </div>
  );
});
