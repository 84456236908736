import React, { memo, useState } from "react";
import * as Yup from "yup";
import { InputText } from "../../../components";
import { Button, CreateAlert, InputWraper, onError, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withTransactionWraper } from "../wraper";
import { translate } from "../../../languages";

export const ManualDeposit = withTransactionWraper(
  memo(() => {
    const { handleSubmit, getInputProps, isSubmitting, resetForm } = useForm({
      structure: [
        {
          name: "transactionHash",
          validate: Yup.string().required("Txhash is required")
            .min(50, "Txhash is too short - should be 50 characters minimum.")
        },
      ],
      onSubmit: async (values) =>
        AdminService.depositManual(values.transactionHash)
          .then((res) => {
            if (res.success) {
              CreateAlert({
                message: "Successfully.",
                type: "success",
              });
              // clearForm();
              resetForm();
            } else {
              CreateAlert({
                message: "Error when submit.",
                type: "danger",
              });
              resetForm();
            }
          })
          .catch((err) => {
            CreateAlert({ type: "danger", message: translate(err.message) });
          }),
    });

    const transactionHash = getInputProps("transactionHash");

    const clearForm = () => {
      transactionHash.onChange("");
    };

    return (
      <div className="row mb20">
        <div className="col-sm-5">
          <form className="blockLists" onSubmit={handleSubmit}>
            <InputWraper label={"Txhash"} inputProps={getInputProps("transactionHash")} component={InputText} />
            <Button label="Submit" isMiddle className="btnBan" type="submit" isLoading={isSubmitting} />
          </form>
        </div>
      </div>
    );
  })
);
