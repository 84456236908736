import React, { useState } from "react";

import moment from "moment";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getEnv } from "../../../configs";
import { Button, CreateAlert, Icon, NumberUtils, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withTransactionWraper } from "../wraper";

export enum EBundleVolumeType {
  F1 = "F1",
  ALL_BRANCH = "ALL_BRANCH",
}

export const BundleVolumePage = withTransactionWraper(() => {
  const [params, setParams] = useState<any>();
  const [totalAmount, setTotalAmount] = useState<any>();

  let structure = [
    {
      name: "Username",
      key: "userName",
      className: "primary-column",
    },
    {
      name: "Time",
      key: "created",
      render: (item: any) => moment(item?.created).format("L HH:mm:ss"),
    },
    {
      name: "Package Name",
      key: "packageName",
    },
    {
      name: "Package Price",
      key: "price",
      render: (item: any) => NumberUtils.toFormatNumber(+item?.price, +getEnv("NUMBER_DECIMAL_DISPLAY")),
    },
  ];

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await AdminService.getBundleVolumeList({
          ...params,
          page: 1,
          pageSize: 10000,
        });

        const fileHead = structure.map((v) => v.name);

        const dataExport = [
          fileHead,
          ...response?.result?.data?.map((item: any) =>
            structure.map((column, index) => {
              if (!column.key) return "";
              if (column.key === "created") return moment(item[column.key]).format("L HH:mm:ss");
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(wb, `Bundle Volume ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
          type: "binary",
        });

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error: any) {
        resolve(CreateAlert({ type: "danger", message: error.message }));
      }
    });
  };

  return (
    <div className="bundle-volume-page">
      <div className="d-flex align-items-center justify-content-between">
        <Button className="mb20" label="Export to Excel" buttonType="success" onClick={handleExportExcel} disabled={params == null} />
        <div className="infor-container">
          <div className="total-amount">
            <div className="total-amount__icon">
              <Icon.TotalPackageIcon />
            </div>
            <div className="total-amount__content">
              ${new Intl.NumberFormat("en").format(totalAmount ?? 0)}
              <span className="sub-text">&nbsp;TOTAL AMOUNT</span>
            </div>
          </div>
        </div>
      </div>
      <Table
        hasSearchButton={true}
        hasOrderColumn={true}
        hasOpenFilter={true}
        filters={[
          {
            name: "Username",
            key: "userName",
            input: TableFilterInputText,
          },
          {
            name: "Type",
            key: "type",
            input: (s) => (
              <TableFilterInputSelect
                {...s}
                options={[
                  { label: "Direct Bundle Volume", value: EBundleVolumeType.F1 },
                  { label: "Community Bundle Volume", value: EBundleVolumeType.ALL_BRANCH },
                ]}
                isClearable={false}
              />
            ),
            defaultValue: EBundleVolumeType.F1,
          },
          {
            name: "Time",
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
          },
        ]}
        structure={structure}
        fetchData={async (state) => {
          setParams(state);
          return AdminService.getBundleVolumeList({
            page: state.pageNumber,
            pageSize: state.limit,
            ...state,
          })
            .then((res) => {
              setTotalAmount(res?.result?.totalAmount);
              return {
                data: res?.result?.data,
                count: res?.result?.total,
              };
            })
            .catch(() => {
              return {
                data: [],
                count: 0,
              };
            });
        }}
      />
    </div>
  );
});
