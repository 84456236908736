import React, { useEffect, useState } from "react";
import { useSelector } from "../../../store";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";
import { Button, onError, InputWraper, useForm, CreateAlert, Icon } from "../../../modules";
import { InputNumber, InputText } from "../../../components";
import { store } from "../../../store";
import { translate } from "../../../languages";
import * as Yup from "yup";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import { ECoinId } from "../../../types";

export const MetConversionConfigPage = withConfigWraper(() => {
    const [mainToMetRate, setMainToMetRate] = useState<any>();
    const [ticketToMetRate, setTicketToMetRate] = useState<any>();
    const [isDisableInput1, setIsDisableInput1] = useState<boolean>(true);
    const [isDisableInput2, setIsDisableInput2] = useState<boolean>(true);
    const [dataPopup, setDataPopup] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { handleSubmit, getInputProps } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "mainToMetRate",
                defaultValue: mainToMetRate ?? 'N/A',
                validate: Yup.number()
                    .typeError(translate("must be a number"))
                    .required(translate("must be provided"))
                    .min(0, translate("must be greater than or equal to 0")),
                isDisabled: isDisableInput1,
            },
            {
                name: "ticketToMetRate",
                defaultValue: ticketToMetRate ?? 'N/A',
                validate: Yup.number()
                    .typeError(translate("must be a number"))
                    .required(translate("must be provided"))
                    .min(0, translate("must be greater than or equal to 0")),
                isDisabled: isDisableInput2,
            },
        ],
        onSubmit: async (values) => {
            let payload = {
                mainToMetRate: +values?.mainToMetRate,
                ticketToMetRate: +values?.ticketToMetRate,
            };
            setDataPopup(payload);
        },
    });

    let handleOnClickConfirmPopup = () => {
        if (isLoading === true) return;
        setIsDisableInput1(true);
        setIsDisableInput2(true);
        setIsLoading(true);

        const payload = {
            isActiveScan: true,
            shouldProcessWithdraw: false,
            value: JSON.stringify({MAIN_TO_MET: +dataPopup?.mainToMetRate, TICKET_TO_MET: +dataPopup?.ticketToMetRate}),
            serverConfigName: "MET_CONVERSION_CONFIG",
        };

        return ConfigService.setConfig(payload).then(async () => {
                ConfigService.getServerConfigByName('MET_CONVERSION_CONFIG').then((res) => {
                    setMainToMetRate(res?.MAIN_TO_MET);
                    setTicketToMetRate(res?.TICKET_TO_MET);
                });
                CreateAlert({
                    type: 'success',
                    message: 'Edit MET Conversion Config Successful'
                });
            }).catch((err) => {
                CreateAlert({
                    type: 'danger',
                    message: translate(err?.message)
                });
            }).finally(() => {
                setDataPopup(null);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        ConfigService.getServerConfigByName('MET_CONVERSION_CONFIG').then((res) => {
            setMainToMetRate(res?.MAIN_TO_MET);
            setTicketToMetRate(res?.TICKET_TO_MET);
        });
    }, []);

    return (
        <div className="row mb20 met-conversion-config-page">
            <div className="col-sm-5">
                <form
                    className="blockLists"
                    onSubmit={handleSubmit}
                >
                    <InputWraper
                        label={"Convert Rate Main Wallet to MET"}
                        inputProps={getInputProps("mainToMetRate")}
                        renderInput={(props) => (
                            <>
                                <InputText {...props} />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput1((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <InputWraper
                        label={"Convert Rate Ticket to MET"}
                        inputProps={getInputProps("ticketToMetRate")}
                        renderInput={(props) => (
                            <>
                                <InputText {...props} />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput2((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <div>
                        <Button
                            label="Submit"
                            isMiddle
                            buttonType="success"
                            type="submit"
                            disabled={mainToMetRate == null || ticketToMetRate == null || (isDisableInput1 && isDisableInput2) || dataPopup != null || ((mainToMetRate === +getInputProps('mainToMetRate').value) && (ticketToMetRate === +getInputProps('ticketToMetRate').value))}
                        />
                    </div>
                </form>
            </div>
            {dataPopup ? (
                <GeneralPopupCompnt
                    onClose={() => {
                        setDataPopup(null);
                    }}
                    onClickConfirm={() => handleOnClickConfirmPopup()}
                    textButton="OK"
                    titlePopup={"WARNING!"}
                    messagePopup={[
                        <>
                            {(mainToMetRate != dataPopup?.mainToMetRate) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">Convert Rate Main Wallet to MET:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{mainToMetRate}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{dataPopup?.mainToMetRate}</span></div>
                                    </div>
                                </div>
                            </>}
                            {(ticketToMetRate != dataPopup?.ticketToMetRate) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">Convert Rate Ticket to MET:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{ticketToMetRate}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{dataPopup?.ticketToMetRate}</span></div>
                                    </div>
                                </div>
                            </>}
                            {((mainToMetRate == dataPopup?.mainToMetRate) && (ticketToMetRate == dataPopup?.ticketToMetRate)) && "No change"}
                        </>,
                    ]}
                />
            ) : null}
        </div>
    );
});
