import React, { useState } from "react";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { Button, CreateAlert, NumberUtils, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import moment from "moment";
import { TradeLayout } from "../component";
import { useSelector } from "../../../store";
import { getEnv } from "../../../configs";

export const bridgeSystem = TradeLayout(() => {
    const coins = useSelector((state) => state.coins);

    const [params, setParams] = useState<any>();
    
    let structure = [
        {
            name: "#Trans",
            key: "orderId",
        },
        {
            name: "Inditator IP",
            key: "ip",
        },
        {
            name: "Product",
            key: "coinId",
            render: (item:any) => coins?.data?.filter((el: any) => el?.coinId === item?.coinId)?.[0]?.code,
        },
        {
            name: "QTY",
            key: "amount",
            render: (item:any) => NumberUtils.toFormatNumber(+item?.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")),
        },
        {
            name: "Time",
            key: "created",
            render: (item:any) => moment(item.created).format("L HH:mm:ss")
        },
        {
            name: "LP Open Price",
            key: "openPriceLp",
        },
        {
            name: "MX Price",
            key: "openPrice",
        },
        {
            name: "Current Position",
            key: "profitLp",
            render: (item:any) => +item?.profitLp - +item?.amount,
        },
        {
            name: "Close Price",
            key: "closePrice",
        }
    ];

    const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
            try {
                const response = await AdminService.getOrderList({
                    ...params,
                    page: 1,
                    pageSize: 10000,
                });

                const fileHead = structure.map((v) => v.name);
                
                const dataExport = [
                    fileHead,
                    ...response?.result?.orders?.map((item: any) =>
                        structure.map((column, index) => {
                            if (!column.key) return "";
                            if (column.key === "created") return moment(item[column.key]).format("L HH:mm:ss");
                            if (column.key === "coinId") return coins?.data?.filter((el: any) => el?.coinId === item[column.key])?.[0]?.code;
                            if (column.key === "profitLp") return +item?.profitLp - +item?.amount;
                            return item[column.key];
                        }),
                    ),
                ];

                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(wb, `Bridge System ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, { type: "binary" });

                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    }),
                );
            } catch (error: any) {
                resolve(CreateAlert({ type: "danger", message: error.message }));
            }
        });
    };

    return (
        <div className="bridge-system-page">
            <Button
                className="mb20"
                label="Export to Excel"
                buttonType="success"
                onClick={handleExportExcel}
            />
            <Table
                hasOrderColumn
                filters={[
                    {
                        name: "Date",
                        key: "created",
                        input: (e) => (
                            <TableFilterRangeTimeInput
                                {...e}
                                fromKey="fromDate"
                                toKey="toDate"
                            />
                        ),
                        defaultValue: {
                            fromDate: moment().startOf("month"), 
                            toDate: moment().endOf("day")
                        },
                    },
                ]}
                structure={structure}
                fetchData={async (state) => {
                    setParams(state);
                    return AdminService.getOrderList({
                        ...state,
                        page: state.pageNumber,
                        pageSize: state.limit,
                    }).then((res) => {
                        return {
                            count: res?.result?.total,
                            data: res?.result?.orders,
                        };
                    });
                }}
            />
        </div>
    );
});
