import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { InputNumber } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, onError, useForm } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";

export const DTEConfig = withConfigWraper(() => {
  const [config, setConfig] = useState(0);

  const { handleSubmit, getInputProps } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "rate",
        defaultValue: Number(config),
        validate: Yup.number().required(translate("must be provided")),
        // .min(1, translate("not filled in zero")),
      },
    ],
    onSubmit: async (values) => {
      return ConfigService.updateConfigDTE({
        rate: values?.rate,
      })
        .then(async () => {
          CreateAlert({ type: "success", message: "Set config DTE rate successful." });
        })
        .catch(onError);
    },
  });

  useEffect(() => {
    ConfigService.getConfigDTE().then((res: any) => setConfig(res.rate));
  }, []);

  return (
    <div className="row mb20">
      <div className="col-sm-5">
        <form className="blockLists" onSubmit={handleSubmit}>
          <InputWraper label={"Rate"} inputProps={getInputProps("rate")} component={InputNumber} />
          <div>
            <Button
              // className="mb20"
              label="Submit"
              // onClick={props.onClick}
              isMiddle
              buttonType="success"
              className="btnBan"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
});
