import { RequestMainService } from "../request";
import { Store } from "redux";
import { SET_COIN_CONVERSION_RATE } from "./coinConversionRate.reducer";
import capitalize from "lodash/capitalize";
import { translate } from "../../languages";
export interface IRequestWithdrawPayload {
  coinId: number;
  feeCoinId: number;
  value: number;
}

export class BankService {
  static async getTransactions(params: any = {}) {
    return RequestMainService.get(`/bank/transaction`, params).then(({ result }) => ({
      count: result.total,
      data: result.transactions,
    }));
  }

  static async getCoinConversionRate(store: Store) {
    return RequestMainService.get(`/bank/rates-to-usd`)
      .then(({ result }) => store.dispatch({ type: SET_COIN_CONVERSION_RATE, data: result }))
      .catch((res) =>
        store.dispatch({
          type: SET_COIN_CONVERSION_RATE,
          error: res.error,
        })
      );
  }

  static getTransactionTypeOptions() {
    return [
      { label: "DEPOSIT", value: 1 },
      { label: "WITHDRAW", value: 2 },
      { label: "RECEIVE_TRANSFER", value: 3 },
      { label: "SEND_TRANSFER", value: 4 },
      { label: "WITHDRAW_FEE", value: 6 },
      { label: "BANK_RECEIVE_FROM_USERS", value: 17 },
      { label: "BANK_SEND_TO_USERS", value: 18 },
      { label: "CONVERT_SENT", value: 19 },
      { label: "CONVERT_RECEIVED", value: 20 },
      { label: "PLACE_INVESTMENT", value: 46 },
      { label: "INVESTMENT_PROFIT", value: 47 },
      // { label: "REFUND", value: 50 },

      // { label: "DAILY_TRANSFER", value: 62 },
      // { label: "RECEIVE_DAILY_TRANSFER", value: 63 },
      { label: "RECEIVE_FEE_ALLOCTION", value: 64 },
      { label: "INVESTMENT_FEE", value: 65 },
      { label: "TICKET_BONUS", value: 66 },
      // { label: "TICKET_RESET", value: 67 },
      // { label: "DAILY_COMMISSION", value: 68 },
      { label: "WORLD_POOL_COMMISSION_1", value: 69 },
      { label: "WORLD_POOL_COMMISSION_2", value: 70 },
      { label: "BUY_PACKAGE", value: 72 },
      { label: "BUY_PROMOTION_CODE", value: 75 },
      { label: "RECEIVE_DAILY_TICKET", value: 73 },
      { label: "TICKET_PROMOTION_CODE", value: 74 },
      { label: "TICKET_EARLY_BIRD_PROMOTION", value: 76 },
      { label: "DIRECT_COMMISSION", value: 14 },
      { label: "RANK_COMMISSION_BONUS_2", value: 80 },
      { label: "RANK_COMMISSION_3", value: 79 },
      { label: "TRADING_COMMISSION", value: 26 },
      { label: "SAME_RANK_COMMISSION", value: 77 },
      { label: "RANK_COMMISSION_BONUS_6", value: 81 },
      { label: "RANK_COMMISSION_BONUS_7", value: 82 },
      { label: "EAT_EXTRA_FEE", value: 83 },
      { label: "EAT_PROMOTION", value: 84 },

      // { label: "INIT", value: 0 },

      // { label: "DEPOSIT_FEE", value: 5 },
      // { label: "SEND_TRANSFER_FEE", value: 7 },
      // { label: "RECEIVE_TRANSFER_FEE", value: 8 },
      // { label: "TRADE_FEE", value: 9 },
      // { label: "OTHER_INCOME", value: 10 },
      // { label: "OTHER_FEE", value: 11 },
      // { label: "BUY_PRESALE_COIN", value: 12 },
      // { label: "REFUND_WITHDRAW_FEE", value: 13 },

      // { label: "BUY_AGENCY_LICENSE", value: 15 },
      // { label: "UPGRADE_MINER", value: 16 },

      // { label: "SEND_EXCHANGE_FEE", value: 21 },
      // { label: "BUY_MINER_COMMISSION", value: 22 },
      // { label: "UPGRADE_MINER_COMMISSION", value: 23 },
      // { label: "SYSTEM_COMMISSION", value: 24 },
      // { label: "CASH_BACK", value: 25 },

      // { label: "REFUND_WITHDRAW", value: 27 },
      // { label: "REFILL_DEMO_ACCOUNT", value: 28 },
      // { label: "RETURN_MINER_FEE", value: 29 },

      // { label: "AIRDROP_TOKEN", value: 48 },
      // { label: "AIRDROP_TOKEN_FROM_USER", value: 49 },
      // { label: "CANCEL_GAME", value: 52 },
      // { label: "EXCHANGE_OUT", value: 53 },
      // { label: "EXCHANGE_OUT_FEE", value: 54 },
      // { label: "EXCHANGE_IN", value: 55 },
      // { label: "GAME_RECOVERY", value: 56 },
      // { label: "GAME_ADJUST", value: 57 },
      // { label: "GAME_TIP", value: 58 },
      // { label: "GAME_GIVE_PROMOTION", value: 59 },
      // { label: "GAME_REFUND", value: 60 },
      // { label: "GAME_CANCEL_TIP", value: 61 },
      { label: "ATM_SUBSCRIBE", value: 85 },
      { label: "ATM_UNSUBSCRIBE", value: 86 },
      // { label: "ATM_RETURN_TICKET", value: 87 },
      // { label: "ATM_TAKEN_TICKET", value: 88 },
      { label: "ATM_PROFIT_TICKET", value: 89 },
      { label: "ATM_FEE", value: 90 },
      { label: "TICKET_FREE", value: 91 },
      { label: "DTE", value: 93 },
      { label: "MET_RANK", value: 94 },
      { label: "MET_BUNDLE", value: 95 },
      { label: "MET_CONVERT_SENT", value: 96 },
      { label: "MET_CONVERT_RECEIVED", value: 97 },
      { label: "MET_TRANSFER", value: 98 },
      { label: "MET_SUR", value: 99 },
      { label: "MET_SUR_SAME_RANK", value: 100 },
      { label: "MEC_TRANSFER_MINING_SENT", value: 101 },
      { label: "MEC_TRANSFER_MINING_RECEIVED", value: 102 },
      { label: "MEC_MINING_SUBSCRIBE", value: 103 },
      { label: "MET_MINIED_MEC", value: 104 },
      { label: "MEC_MINIED_MEC", value: 105 },
      { label: "REFUND_MEC_MINING_SUBSCRIBE", value: 106 },
    ].map((item) => ({ ...item, label: translate(item.label) }));
    // ].map((v) => ({ ...v, label: capitalize(v.label.replace(/_/g, " ")) }));
  }

  static getStatusOptions() {
    return [
      { label: "JUST_CREATED", value: "JUST_CREATED" },
      { label: "SUCCESS", value: "SUCCESS" },
      { label: "REJECTED", value: "REJECTED" },
      { label: "PROCESSING", value: "PROCESSING" },
    ].map((v) => ({ ...v, label: capitalize(v.label.replace(/_/g, " ")) }));
  }
}
