import React, { FC, useEffect, useState } from "react";
import { UserService } from "../../services/user";
import { translate } from "../../languages";
import { Icon } from "../icon";
import { NavLink, useLocation } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { AdminService } from "../../services/admin";

export const Navigator: FC = () => {
  return (
    <div className="Navigator">
      <img src="/assets/images/logo-symbol-white.png" alt="" className="logo" />
      <MenuLinks />
    </div>
  );
};

const MenuLinks: FC = () => {
  const { pathname } = useLocation();

  const [mecPoolList, setMecPoolList] = useState<any>();
  
  useEffect(() => {
    AdminService.getMecDashboardPool().then((res: any) => {
      setMecPoolList(res?.result);
    });
  }, []);
  
  return (
    <div className="menu">
      <NavLink exact to={Routes.dashboard.path}>
        <Icon.Dashboard />
        Dashboard
      </NavLink>
      {/*
            <NavLink to={Routes.userList.path}>
                <Icon.User />
                Users
            </NavLink>

            <NavLink to={Routes.transactionList.path}>
                <Icon.Exchange />
                Transactions
            </NavLink>

            <NavLink to={Routes.ClaimList.path}>
                <Icon.ClaimIcon />
                Claim
            </NavLink>

            <NavLink to={Routes.franchiseTable.path}>
                <Icon.Affiliate />
                Franchise
            </NavLink>

            <NavLink to={Routes.reportSystem.path}>
                <Icon.Report />
                Reports
            </NavLink>

            <NavLink to={Routes.withdrawConfig.path}>
                <Icon.Setting />
                Config
            </NavLink>

            <div className="btnLogout" onClick={() => UserService.logout()}>
                <Icon.Logout />
                {translate('logout')}
            </NavLink> */}

      <NavLink
        to={Routes.userList.path}
        // isActive={() => {
        //   return pathname === "/" || pathname === Routes.userList.path;
        // }}
      >
        <Icon.User />
        Users
      </NavLink>

      <NavLink to={Routes.transactionList.path}>
        <Icon.TransactionIcon />
        Transactions
      </NavLink>

      {/* <NavLink to={Routes.ExperienceGameList.path}>
        <Icon.ExperienceGameIcon />
        Experience Game
      </NavLink> */}

      {/* <NavLink to={Routes.ClaimList.path}>
        <Icon.ClaimIcon />
        Claim
      </NavLink> */}

      <NavLink to={Routes.franchiseTable.path}>
        <Icon.Affiliate />
        Franchise
      </NavLink>

      <NavLink
        to={Routes.challengeGlobalPartner.path}
        isActive={() => {
          return pathname.includes("global-partner") || pathname.includes("partner") || pathname.includes("g4-to-md");
        }}
      >
        <Icon.Collection />
        Challenge
      </NavLink>

      <NavLink to={Routes.atmRoute.path}>
        <Icon.AtmIcon />
        ATM
      </NavLink>

      <NavLink 
        to={`${Routes.mecDashboardPool.renderPath(mecPoolList?.[0]?.mecMiningPoolConfigId)}`}
        isActive={() => {
          return (
            pathname.includes(`/mec-dashboard/pool`)
          );
        }}
      >
        <Icon.MecDashboardIcon />
        MEC Dashboard
      </NavLink>

      {/* <NavLink
        to={Routes.OrdersPage.path}
        isActive={() => {
          return (
            pathname.includes("/game-management/orders") ||
            pathname.includes("/game-management/supplier") ||
            pathname.includes("/game-management/game")
          );
        }}
      >
        <Icon.GameManagementIcon />
        Game Management
      </NavLink> */}

      <NavLink to={Routes.reportSystem.path}>
        <Icon.Report />
        Reports
      </NavLink>

      <NavLink
        to={Routes.intervention.path}
        // isActive={() => {
        //   return (
        //     pathname.includes("candle-control")
        //   );
        // }}
      >
        <Icon.TradeSetting />
        Trade
      </NavLink>

      <NavLink
        to={Routes.withdrawConfig.path}
        isActive={() => {
          return (
            pathname.includes("withdraw") ||
            pathname.includes("change-password") ||
            pathname.includes("activities") ||
            pathname.includes("maintenance") ||
            pathname.includes("role-permission") ||
            pathname.includes("announcement") ||
            pathname.includes("leverage") ||
            pathname.includes("change-wallet") ||
            pathname.includes("met-conversion-config") ||
            pathname.includes("mec-mining-config")
          );
        }}
      >
        <Icon.Setting />
        Config
      </NavLink>

      <NavLink
        to={Routes.SupportCenter.path}
      >
        <Icon.SupportCenter />
        Support Center
      </NavLink>

      <div className="btnLogout" onClick={() => UserService.logout()}>
        <Icon.Logout />
        {translate("logout")}
      </div>
    </div>
  );
};
