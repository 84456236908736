import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../../../AppRoutes";
import { Icon } from "../../../../components";

export const TradeLayout = (Component: FC<any>) => (props: any) => {
  return (
    <div className="trade-layout-compnt">
      <div className="TabLinks">
        {/* <NavLink to={Routes.candleControlSetting.path} exact={true}>
          <Icon.Setting />
          Candle Control Setting
        </NavLink> */}

        <NavLink to={Routes.intervention.path} exact={true}>
          <Icon.Setting />
          Intervention
        </NavLink>

        <NavLink to={Routes.slippage.path} exact={true}>
          <Icon.Setting />
          Slippage
        </NavLink>

        <NavLink to={Routes.bridgeSystem.path} exact={true}>
          <Icon.Setting />
          Bridge System
        </NavLink>

        {/* <NavLink to={Routes.leverageSetting.path} exact={true}>
                    <Icon.Setting />
                    Leverage Setting
                </NavLink> */}
      </div>
      <Component {...props} />
    </div>
  );
};
