import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Icon } from "../../../../components";
import { Routes } from "../../../../AppRoutes";
import { AdminService } from "../../../../services/admin";

const TabLinks = () => {

    const [mecPoolList, setMecPoolList] = useState<any>();

    useEffect(() => {
        AdminService.getMecDashboardPool().then((res: any) => {
            setMecPoolList(res?.result);
        });
    }, []);

    return (
        <div className="TabLinks">
            {mecPoolList?.map((item:any) => {
                return (
                    <NavLink
                        to={`${Routes.mecDashboardPool.renderPath(item?.mecMiningPoolConfigId)}`}
                        exact={true}
                    >
                        <Icon.Setting />
                        {item?.name}
                    </NavLink>
                )
            })}
        </div>
    );
};

export const MECDashboardLayout = (Component: any) => (props: any) => {

    return (
        <div className="mec-dashboard-layout">
            <TabLinks />
            <Component {...props} />
        </div>
    );
};
