import React from "react";
import { Button } from "../../../modules";
import { withConfigWraper } from "../wraper";
import LeverageCompnt from "./leverage-compnt";

type Props = {};

const LeveragePage = withConfigWraper((props: Props) => {
  return (
    <div className="row mb20">
      <div className="col-sm-12">
        <LeverageCompnt />
      </div>
    </div>
  );
});

export default LeveragePage;
