import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";

export interface IFranchiseWraperProps extends RouteComponentProps {}

export const withFranchiseWraper = (Component: FC<IFranchiseWraperProps>) => (props: IFranchiseWraperProps) => {
  return (
    <div className="withFranchiseWraper">
      <div className="TabLinks">
        <NavLink to={Routes.franchiseTable.path} exact={true}>
          <Icon.Table />
          Table
        </NavLink>

        <NavLink to={Routes.franchiseTree.path} exact={true}>
          <Icon.Tree />
          Tree
        </NavLink>

        <NavLink to={Routes.franchiseCommissionSetting.path} exact={true}>
          <Icon.Setting />
          Commission Setting
        </NavLink>

        <NavLink to={Routes.franchiseCommissionHistory.path} exact={true}>
          <Icon.Setting />
          Commission History
        </NavLink>

        <NavLink to={Routes.franchiseWithdrawalHistory.path} exact={true}>
          <Icon.Setting />
          Withdrawal History
        </NavLink>

        <NavLink to={Routes.franchiseDepositHistory.path} exact={true}>
          <Icon.Setting />
          Deposit History
        </NavLink>

        <NavLink to={Routes.ticketBonus.path} exact={true}>
          <Icon.Setting />
          Ticket Bonus
        </NavLink>

        {/* <NavLink to={Routes.franchiseLevelSettingHistory.path} exact={true}>
            <Icon.HistoryIcon />
            Level Setting History
          </NavLink>

          <NavLink to={Routes.franchiseLeader.path} exact={true}>
            <Icon.Leader />
            Leader
          </NavLink> */}
      </div>

      <Component {...props} />
    </div>
  );
};
