import React, { useState } from "react";

import moment from "moment";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { Button, CreateAlert, Icon, NumberUtils, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withTransactionWraper } from "../wraper";

export const G4SearchPage = withTransactionWraper(() => {
    const [params, setParams] = useState<any>();

    let structure = [
        {
            name: "G4 Main Account",
            key: "userName",
        },
        {
            name: "PIN",
            key: "pinCode",
        },
        {
            name: "G4 Sub 1",
            key: "sub[0]?.userName",
            render: (item:any) => item?.sub[0]?.userName
        },
        {
            name: "G4 Sub 2",
            key: "sub[1]?.userName",
            render: (item:any) => item?.sub[1]?.userName
        },
        {
            name: "G4 Sub 3",
            key: "sub[2]?.userName",
            render: (item:any) => item?.sub[2]?.userName
        },
    ];

    const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
            try {
                const response = await AdminService.getG4SearchList({
                    ...params,
                    page: 1,
                    pageSize: 10000,
                });

                const fileHead = structure.map((v) => v.name);

                const dataExport = [
                    fileHead,
                    ...response?.result?.data?.map((item: any) =>
                        structure.map((column, index) => {
                            if (!column.key) return "";
                            if (column.key === "sub[0]?.userName") return item['sub']?.[0]?.userName;
                            if (column.key === "sub[1]?.userName") return item['sub']?.[1]?.userName;
                            if (column.key === "sub[2]?.userName") return item['sub']?.[2]?.userName;
                            return item[column.key];
                        }),
                    ),
                ];

                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(wb, `G4 Search ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
                    type: "binary",
                });

                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    }),
                );
            } catch (error: any) {
                resolve(CreateAlert({ type: "danger", message: error.message }));
            }
        });
    };

    return (
        <div className="g4-search-page">
            <div className="d-flex align-items-center justify-content-between">
                <Button
                    className="mb20"
                    label="Export to Excel"
                    buttonType="success"
                    onClick={handleExportExcel}
                    disabled={params == null}
                />
            </div>
            <Table
                hasSearchButton={true}
                hasOrderColumn={true}
                hasOpenFilter={true}
                filters={[
                    {
                        name: "Username",
                        key: "userName",
                        input: TableFilterInputText,
                    },
                    {
                        name: "Time",
                        key: "created",
                        input: (e) => (
                            <TableFilterRangeTimeInput
                                {...e}
                                fromKey="fromDate"
                                toKey="toDate"
                            />
                        ),
                    },
                ]}
                structure={structure}
                fetchData={async (state) => {
                    if (state?.["fromDate"]) state["fromDate"] = moment(state?.["fromDate"]).toISOString();
                    if (state?.["toDate"]) state["toDate"] = moment(state?.["toDate"]).toISOString();
                    setParams(state);
                    return AdminService.getG4SearchList({
                        page: state.pageNumber,
                        pageSize: state.limit,
                        ...state,
                    }).then((res) => {
                        return {
                            data: res?.result?.data,
                            count: res?.result?.total,
                        };
                    }).catch(() => {
                        return {
                            data: [],
                            count: 0,
                        };
                    });
                }}
            />
        </div>
    );
});
