import React from "react";
import { NumberUtils, Table } from "../../../modules";
import { ChallengeService } from "../../../services/challenge";
import { withChallengeWraper } from "../wraper";
import moment from "moment";

export const G4ToMD = withChallengeWraper(() => {
  return (
    <Table
      notShowPagination
      structure={[
        {
          name: "Username",
          key: "userName",
        },
        {
          name: "Period",
          key: "period",
          render: (item) => {
            const type = item.months[0].challengeType;
            return `${moment(item.months[0].fromDate).local().format("DD/MM/YY")}${type === "MD" ? ` - ${moment(item.months[0].toDate).local().format("DD/MM/YY")}` : ""}`;
          },
        },
        {
          name: "Group Sales",
          key: "groupSales",
          render: (item) => NumberUtils.toFormatNumber(+item?.months[0]?.zoneVolume),
        },
      ]}
      fetchData={async (state) => {
        return ChallengeService.challengeDashboard("MD");
      }}
    />
  );
});
